<template>
  <div class="home" >
    <div class="home-area" style="" >
      <HelloWorld msg="您好，" :username="name" />
      <p></p>
      <p>歡迎使用松福派車系統</p>
      <p>&nbsp;</p>

      <div v-if="news.length">
        <h2 class="fw-bold pb-3" ><img style="height: 36px; position: relative; top: -3px; width: auto;" src="//songf-server.cglandmark.net/public/images/logo.png">最新消息公告</h2>
        <div class="row">
          <div class="col-md-4" v-for="article in news" :key="article.id">
            <div class="card mb-4">
              <img :src="'https://songf-server.cglandmark.net' + article.cover_image" class="card-img-top" alt="Cover image">
              <div class="card-body">
                <h5 class="card-title">{{ article.title }}</h5>
                <div class="card-text" v-html="article.content"></div>
              </div>
              <div class="card-footer text-muted">
                日期 {{ article.date }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import axios from 'axios';

export default {
  name: "HomeView",
  data() {
    return {
      name: '',
      phone: '',
      news: [],
    }
  },
  components: {
    HelloWorld,
  },
  mounted() {
    this.checkLocalStorage();
    this.fetchNews();
  },
  methods: {
    checkLocalStorage() {
      const name = localStorage.getItem('name');
      const phone = localStorage.getItem('phone');
      this.name = name;
      this.phone = phone;
    },
    fetchNews() {
      axios.get('https://songf-server.cglandmark.net/api/news.php')
        .then(response => {
          this.news = response.data;
        })
        .catch(error => {
          console.log(error);
          this.news = [];
        });
    },
  },
};
</script>

<style scoped>
.home {
  background-image: url('//songf-server.cglandmark.net/public/images/bannerv2.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 5em; 
}
.home-area {
  padding-top: 30vh; 
}
@media only screen and (min-width: 768px) {
  .home {
    background-image: url('//songf-server.cglandmark.net/public/images/bannerv2.jpg');
    background-size: 45%;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: 100vh;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5em;
    background-position: 38% 0%;
  }
  .home-area {
    padding-top: 45vh;
    max-width: calc( 100vw - 240px );
  }
}
</style>
